@import 'assets/sass/theme.scss';
@import 'assets/sass/app.scss';
@import "~@ng-select/ng-select/themes/default.theme.css";
ng-select.ng-invalid.is-invalid .ng-select-container {
    border-color: #dc3545;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}
.edit-order {
    display: block !important;
}
.full-red-border {
    border-color: #dc3545 !important; 
}
.left-red-border {
    border-color: #dc3545 !important; 
}
a {
    cursor: pointer !important;
}
.login-container {
  overflow: scroll
}
/* Auth Pages */
.auth-logo-image {
    height: 100px;
    width: auto;
    margin-bottom: 71px;
}
/* Toastr Customisation */
.ngx-toastr {
    right: 16px !important;
}
.slug-preview {
    position: relative;
    top: 0;
    display: block;
    left: 0;
}
.slug-preview p {
    text-align: left;
}

.custom-invalid-feedback p {
    text-align: right;
    font-size: 80%;
    padding-top: 3px;
}
.custom-invalid-feedback div {
    text-align: right;
    font-size: 80%;
    color:#dc3545;
    padding-top: 3px;
}
 

.print-window { 
    padding-left: 50px;
    padding-right: 50px;
}
.print-window .print-icon, .print-window .breadcrump {
    display: none;
}
.print-window .right-area {
    width: 400px !important;
}
.print-window .left-area {
    width: 400px !important;
}
.print-window .left-area .col-lg-4 {
    width: 50% !important;
}
.print-window  .table-details {
    display: inline !important;
}
.print-window .print-hidden {
    display: none;
}
.print-window .left-area h6, .print-window td span .print-window th span {
    font-size: 12px !important;
} 



.today1{
    background-color: #5cb85c !important;
    color: white !important;
  }