$theme-color: #374abe;
$theme-bg: #f2f5ff;

html,
body {
    height: 100%;
}

body {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}

img {
    max-width: 100%;
}
.f-10 {
    font-size: 10px;
}
.f-12 {
    font-size: 12px;
}

.f-16 {
    font-size: 16px;
}

.f-18 {
    font-size: 18px;
}

.f-20 {
    font-size: 20px;
}

.f-24 {
    font-size: 24px;
}

.f-30 {
    font-size: 30px;
}

.f-36 {
    font-size: 36px;
}

.f-46 {
    font-size: 46px;
}

.w-md-50 {
    width: 50% !important;
}

.font-weight-semi-bold {
    font-weight: 500 !important;
}
a{color: #374abe}
a,
a:hover {
    text-decoration: none;
    outline: none;
}

.btn:focus,
.form-control:focus {
    box-shadow: none !important
}

ul {
    list-style: none;
}

.cursor-p {
    cursor: pointer;
}
.form-control {
    box-shadow: none !important;
}
.text-black {
    color: #000;
}
.text-indigo {
    color: #374abe;
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 0.5;
  font-size: 13px;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 0.5;
  font-size: 13px;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 0.5;
  font-size: 13px;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 0.5;
  font-size: 13px;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 0.5;
  font-size: 13px;
}